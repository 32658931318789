import React from 'react';
import styled from 'styled-components';

export const Paragraph = ({
  children,
  big,
  noMargin,
  white,
  center,
  className,
}) => (
  <ParagraphStyles
    className={className}
    noMargin={noMargin}
    white={white}
    center={center}
    big={big}
  >
    {children}
  </ParagraphStyles>
);

export const ParagraphStyles = styled.p`
  margin: ${(props) => (props.noMargin ? '0' : '0 0 1.8rem 0')};
  font-size: ${(props) => (props.big ? '2.4rem' : '1.8rem')};
  color: ${(props) => (props.white ? 'var(--white)' : 'var(--black)')};
  line-height: ${(props) => (props.big ? '120%' : '2.5rem')};
  text-align: ${(props) => (props.center ? 'center' : 'unset')};
  font-weight: normal;

  @media (min-width: 768px) {
    font-size: ${(props) => (props.big ? '3.2rem' : '1.8rem')};
    line-height: ${(props) => (props.big ? '4.1rem' : '2.5rem')};
  }
`;

export const Bold = styled.strong`
  font-weight: 600;
`;

export const Italic = styled.span`
  font-family: 'Gineto-Italic';
  font-weight: normal;
`;
