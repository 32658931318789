import React from 'react';
import styled from 'styled-components';
import { Paragraph } from '../components/shared/Paragraph';

export default function FourOhFourPage() {
  return (
    // TODO: add texts to sanity
    <FourOhFourPageContainer>
      <FourOhFourPageContent>
        <h1>Ops!</h1>
        <Paragraph big>Noe gikk galt...</Paragraph>
      </FourOhFourPageContent>
    </FourOhFourPageContainer>
  );
}

const FourOhFourPageContainer = styled.div`
  padding: 2rem;
  background-color: var(--seaGreen);
`;

const FourOhFourPageContent = styled.div`
  max-width: var(--gridWidthTablet);
  margin: 0 auto;
`;
